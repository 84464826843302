import { Spinner, Tab } from '@fluentui/react-components';
import { SignOutRegular } from '@fluentui/react-icons';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { LogoWithText } from '@/features/layout/components/Logo';
import { useStore } from '@/stores';
import { CommonProps } from '@/utils/props';

import { Paper } from '../Paper';
import { LogOutButton, StyledLink, StyledTabList } from './styles';

export type NavigationBarProps = CommonProps<{ children?: never }>;

export const NavigationBar: FC<NavigationBarProps> = observer(() => {
  const { appStore } = useStore();
  const location = useLocation();

  const isCreateMeetingPage = location.pathname.startsWith('/create-meeting');
  const isMyMeetingsPage = location.pathname.startsWith('/my-meetings');
  const isAboutPage = location.pathname.startsWith('/about');
  const tabValue = isCreateMeetingPage
    ? 'create-meeting'
    : isMyMeetingsPage
      ? 'my-meetings'
      : isAboutPage
        ? 'about'
        : undefined;

  const handleSignOutClick = useCallback(() => {
    appStore.authStore.signOut();
  }, []);

  return (
    <Paper padding={[0, 3]} direction="end" spacing={2}>
      <LogoWithText />
      <StyledTabList selectedValue={tabValue}>
        <StyledLink to="/create-meeting">
          <Tab value="create-meeting">Create Meeting</Tab>
        </StyledLink>
        <StyledLink to="/my-meetings">
          <Tab value="my-meetings">My Meetings</Tab>
        </StyledLink>
        <StyledLink to="/about">
          <Tab value="about">About</Tab>
        </StyledLink>
      </StyledTabList>
      <LogOutButton
        icon={appStore.authStore.loading.is('signOut') ? <Spinner size="tiny" /> : <SignOutRegular />}
        onClick={handleSignOutClick}
      >
        Sign Out
      </LogOutButton>
    </Paper>
  );
});
