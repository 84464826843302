import { Button, Link, Subtitle1, Title3 } from '@fluentui/react-components';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { getUserBusyEvents } from '@/api/calendar';
import { Paper } from '@/components/Paper';
import { useReactiveRef } from '@/hooks/useReactiveRef';
import { ProviderFeature } from '@/shared';

import { Status } from '../api/meetings';
import LimitPopup from '../components/LimitPopup';
import TimeSlots from '../components/TimeSlots';
import { VotingAuthorizationPopup, VotingAuthorizationPopupRef } from '../components/VotingAuthorizationPopup';
import { workingTime } from '../constants';
import { reArrangeSlots } from '../helpers/slots';
import { getSmallestVote, mapChoices } from '../helpers/voting';
import { useStore } from '../stores';
import { replaceNull } from '../utils/null';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled(Title3)`
  display: flex;
  justify-content: center;
`;

const ConfirmPanel = styled.div``;

const ButtonInnerWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const ProductLinks = styled.span`
  padding-top: 25px;
  display: block;
  text-align: center;
  color: #5f6368;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
`;

const Footer: FC = (): ReactElement => {
  return (
    <ProductLinks>
      This meeting was organized using the{' '}
      <Link inline href="https://appsource.microsoft.com/en-us/product/web-apps/WA200006814?tab=Overview">
        Youkeeps app.
      </Link>{' '}
      Interested in effortlessly scheduling your meetings?{' '}
      <Link inline href="https://youkeeps.com/">
        Click here
      </Link>{' '}
      to find out more.
    </ProductLinks>
  );
};

const Voting: FC = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const [isTokenValid, setIsTokenValid] = useState<boolean | undefined>(undefined);
  const [isMeetingCancelled, setIsMeetingCancelld] = useState<boolean>(false);
  const [selectedSlotIds, setSelectedSlotIds] = useState<string[]>([]);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [isMeetingSent, setIsMeetingSet] = useState<string | undefined>(undefined);
  const [isLimitPopupOpen, setIsLimitPopupOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isDisabledFieldsAfterConfirm, setIsDisabledFieldsAfterConfirm] = useState<boolean>(true);
  const [authPopupRef, authPopupRefGetter, authPopupRefSetter] = useReactiveRef<VotingAuthorizationPopupRef>(null);
  const [isAuthorizationPopupOpen, setIsAuthorizationPopupOpen] = useState<boolean>(true);
  const [userEventsMatchingSlotIds, setUserEventsMatchingSlotIds] = useState<string[]>([]);
  const { appStore } = useStore();
  const navigate = useNavigate();

  const token = useMemo(() => replaceNull(searchParams.get('token')), [searchParams]);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const event = appStore.freeDateMeeting;
  const userAlreadyVoted = !!event?.votes.find((item) => item.participant === appStore.email);
  const totalParticipants = (event?.participants.length ?? 0) + (event?.optionalParticipants.length ?? 0);
  const uniqueVotes = new Set(
    event?.votes.filter(
      (item) => event.participants.includes(item.participant) || event.optionalParticipants.includes(item.participant),
    ),
  );
  const isLastVoter = totalParticipants - (uniqueVotes?.size || 0) === 1;

  const eventIsOutdated = moment
    .unix(Math.max(...(event?.slots || []).map((item) => moment(item.start).unix())))
    .isBefore(moment());
  const eventCanBeVoted = !userAlreadyVoted && !eventIsOutdated;

  useEffect(() => {
    if (!token) {
      navigate('/error');
    }
  }, [appStore.isInTeams]);

  const isAlreadySignedIn = appStore.authStore.isSignedIn;
  const authIntegrationProvider = appStore.authStore.integrationProvider;

  // If user is already signed in using Microsoft, we trigger the events
  // fetching immediately after the popup is opened
  useEffect(() => {
    if (
      !isAuthorizationPopupOpen ||
      !isAlreadySignedIn ||
      !authPopupRef.current ||
      authPopupRef.current.isLoading ||
      authPopupRef.current.isError
    ) {
      return;
    }

    if (authIntegrationProvider === 'microsoft') {
      authPopupRef.current.triggerMicrosoftAuth();
    } else if (authIntegrationProvider === 'google') {
      authPopupRef.current.triggerGoogleAuth();
    }
  }, [isAuthorizationPopupOpen, isAlreadySignedIn, authIntegrationProvider, authPopupRefGetter]);

  useEffect(() => {
    if (!token || !eventCanBeVoted) return;

    void (async () => {
      const data = await appStore.getFreeDateMeetingByToken(token);
      setIsTokenValid(
        !!data?.meeting.id &&
          // Сhecking the token to see if it belongs to the required participant
          data?.meeting.participants
            .map((participant) => participant.toLowerCase().trim())
            .includes(data.tokenData.email.toLowerCase().trim()),
      );

      setIsMeetingCancelld(data?.meeting.status === Status.CANCELLED);
    })();
  }, [token, eventCanBeVoted]);

  const existingSlots = appStore.freeDateMeeting?.slots || [];
  const requiredParticipantVotes = (event?.votes ?? []).filter((vote) =>
    event?.participants.includes(vote.participant),
  );
  const smallestVote = getSmallestVote(requiredParticipantVotes);
  const choicesList = mapChoices({
    existingSlots,
    slotsIds: smallestVote?.slotIds || [],
    hideDisabled: true,
  });

  const totalActiveChoices = choicesList
    .map((item) => item.slots)
    .flat()
    .filter((item) => !item.isDisabled).length;

  const selectedSlotsPercentage = selectedSlotIds.length / totalActiveChoices;

  const fetchCalendarEvents = useCallback(async () => {
    const isCalendarAuthorized = appStore.votingCalendarTokenSaveId || isAlreadySignedIn;
    if (!isCalendarAuthorized) return;

    const userBusyEventsResponse = await getUserBusyEvents({
      tokenSaveId: appStore.votingCalendarTokenSaveId,
    });
    const userBusyEvents = userBusyEventsResponse.data;

    const matchingSlotIds: string[] = [];
    const nonMatchingSlotIds: string[] = [];

    // We don't need to use moment.utc() because of graphAPI response, it's already in UTC
    const events = userBusyEvents.map((item) => ({ start: item.start.dateTime, end: item.end.dateTime }));

    // Get slots w\o those that matches with user calendar events by time
    const reArrangedSlots = reArrangeSlots({
      interval: appStore.freeDateMeeting?.interval || '00:30',
      events: events,
      slots: appStore.freeDateMeeting?.slots ?? [],
      workingTime,
    });

    const choicesMap = new Map();
    choicesList.forEach((choice) => {
      choice.slots.forEach((slot) => {
        choicesMap.set(slot.date, slot.id);
      });
    });
    // Push those slot ID's that appear in both reArrangedSlots and choicesList
    reArrangedSlots.forEach((slot) => {
      const slotDate = slot.start;
      if (choicesMap.has(slotDate)) {
        matchingSlotIds.push(choicesMap.get(slotDate));
      }
    });

    // Find those slot ID's that we will show as outlook matched
    choicesList.forEach((choice) => {
      choice.slots.forEach((slot) => {
        if (!matchingSlotIds.includes(slot.id)) {
          nonMatchingSlotIds.push(slot.id);
        }
      });
    });

    setUserEventsMatchingSlotIds(nonMatchingSlotIds);
  }, [appStore.votingCalendarProviderType, JSON.stringify(choicesList)]);

  const handleCalendarSignIn = useCallback(async ({ providerType }: { providerType: 'microsoft' | 'google' }) => {
    if (!isAlreadySignedIn) {
      const accessToken =
        providerType === 'microsoft'
          ? await appStore.microsoftStore.acquireAccessTokenPopup({
              features: [ProviderFeature.readOnlyCalendar],
            })
          : await appStore.googleStore.acquireAccessTokenPopup({
              features: [ProviderFeature.readOnlyCalendar],
              // It's important to force consent because it's possible that user
              // already gave permission previously and we won't get refresh token.
              // Since we don't save tokens acquired on the voting page (as we do with
              // the site-wide authentication) the only way to make sure we get the
              // refresh token is to force consent. At the moment we don't support
              // integrations without refresh token.
              forceConsent: true,
            });

      if (!accessToken) {
        throw new Error('No access token received');
      }

      appStore.votingCalendarTokenSaveId = accessToken;
      appStore.votingCalendarProviderType = providerType;
    } else {
      appStore.votingCalendarProviderType = authIntegrationProvider;
    }
  }, []);

  const selectAllSlots = useCallback(() => {
    setSelectedSlotIds(
      choicesList
        .flatMap((item) => item.slots)
        .filter((item) => {
          const isAlreadySelected = selectedSlotIds.includes(item.id);
          const isConflicting = userEventsMatchingSlotIds.includes(item.id);

          return isAlreadySelected || !isConflicting;
        })
        .map((item) => item.id),
    );
  }, [JSON.stringify(choicesList), selectedSlotIds, userEventsMatchingSlotIds]);

  const unselectAllSlots = () => {
    setSelectedSlotIds([]);
  };

  const handleSlotsLoading = useCallback(async () => {
    await fetchCalendarEvents();
  }, [fetchCalendarEvents, selectAllSlots]);

  const confirmMeeting = async () => {
    if (!event || !appStore.email || selectedSlotIds.length === 0 || !token) return;
    setIsDisabledFieldsAfterConfirm(false);
    const payload = {
      token,
      slotIds: selectedSlotIds,
    };

    const result = await appStore.voteForMeeting(payload, token);

    if (result?.votes.find((item) => item.participant === appStore.email)) setConfirmed(true);
    if (result?.isMeetingSent) setIsMeetingSet(result.winningSlot?.start);
  };

  if (isTokenValid === undefined) {
    return (
      <Paper spacing={3}>
        <Container>
          <Title>Loading...</Title>
          <Footer />
        </Container>
      </Paper>
    );
  }

  if (isTokenValid === false) {
    return (
      <Paper spacing={3}>
        <Container>
          <Title>Token is invalid</Title>
          <Footer />
        </Container>
      </Paper>
    );
  }

  if (isMeetingCancelled === true) {
    return (
      <Container>
        <Title>The meeting is cancelled by the organizer</Title>
        <Footer />
      </Container>
    );
  }

  if (confirmed) {
    return (
      <Paper spacing={3}>
        <Container>
          <Title>Thank you!</Title>
          {isMeetingSent ? (
            <Title>{`The meeting is confirmed for ${moment(isMeetingSent).format('MMMM Do YYYY, HH:mm (UTCZ)')}!`}</Title>
          ) : (
            <Title>You will receive the final invitation as soon as every attendee confirms the slots</Title>
          )}
          <Footer />
        </Container>
      </Paper>
    );
  }

  const title = isLastVoter
    ? 'Please select at least one convenient slot to confirm the meeting'
    : selectedSlotIds.length === totalActiveChoices
      ? `Awesome, you've selected all available slots!`
      : isChecked
        ? selectedSlotsPercentage > 0.3
          ? 'Looking good! But please consider if you can add more slots, that will help other attendees'
          : 'Please add more slots, it will give other attendees more choices'
        : 'Grab as many available slots as possible:';

  return (
    <Paper spacing={3}>
      {eventCanBeVoted && event ? (
        <>
          <VotingAuthorizationPopup
            ref={authPopupRefSetter}
            isOpen={isAuthorizationPopupOpen}
            onSignIn={handleCalendarSignIn}
            onSlotsFetching={handleSlotsLoading}
            onClose={() => {
              setIsAuthorizationPopupOpen(false);
              selectAllSlots();
            }}
          />
          <Subtitle1>{title}</Subtitle1>
          <TimeSlots
            isDisabledToInteract={isDisabledFieldsAfterConfirm}
            event={event}
            selectAllSlots={() => selectAllSlots()}
            unselectAllSlots={() => unselectAllSlots()}
            choicesList={choicesList}
            selectedSlotIds={selectedSlotIds}
            setSelectedSlotIds={setSelectedSlotIds}
            voterEmail={appStore.email}
            timeZone={`(${moment.tz(timeZone).format('Z')}) ${timeZone}`}
            externalCalendarSource={appStore.votingCalendarProviderType}
            userEventsMatchingSlotIds={userEventsMatchingSlotIds}
          />
          <ConfirmPanel>
            <ButtonInnerWrapper>
              <Button
                appearance="primary"
                disabled={appStore.isLoading || selectedSlotIds.length === 0}
                onClick={
                  selectedSlotsPercentage > 0.3 || isLastVoter ? confirmMeeting : () => setIsLimitPopupOpen(true)
                }
              >
                Confirm
              </Button>
            </ButtonInnerWrapper>
          </ConfirmPanel>
          <Footer />
          {!isLastVoter && (
            <LimitPopup
              onConfirm={confirmMeeting}
              selectedSlotIds={selectedSlotIds}
              availableSlotsAmount={totalActiveChoices}
              open={isLimitPopupOpen}
              onAdd={() => {
                setIsLimitPopupOpen(false);
                setIsChecked(true);
              }}
            />
          )}
        </>
      ) : (
        <>
          {userAlreadyVoted ? (
            <Container>
              <Title>Current user has already voted</Title>
              <Footer />
            </Container>
          ) : (
            <Container>
              <Title>The link has expired</Title>
              <Footer />
            </Container>
          )}
        </>
      )}
    </Paper>
  );
};

export default observer(Voting);
