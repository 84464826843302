import { Button, Subtitle1, Theme } from '@fluentui/react-components';
import {
  ChartPersonRegular as IconChartPerson,
  Check24Regular as IconDescription,
  ClockAlarmRegular as IconClockAlarm,
  ClockRegular as IconClock,
  GlobeRegular as IconGlobe,
  LocationRegular as IconLocation,
  MailClock24Regular as IconMailClock,
  PeopleAddRegular as IconPeopleAdd,
  PersonInfo16Regular as IconOptionalPeopleAdd,
  SendRegular as IconSend,
} from '@fluentui/react-icons';
import FullCalendar from '@fullcalendar/react';
import { isNil } from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import { FC, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Paper } from '@/components/Paper';

import { AutocompleteOption } from '../components/Autocomplete';
import { AutocompletePeopleRef } from '../components/AutocompletePeople';
import { AutocompletePeopleField } from '../components/AutocompletePeopleField';
import ButtonInnerWrapper from '../components/ButtonInnerWrapper';
import Calendar, { CalendarEvent } from '../components/Calendar';
import { TeamsFxContext } from '../components/Context';
import DropDownField from '../components/DropDownField';
import DropDownFieldForOneLiner from '../components/DropDownFieldForOneLiner';
import DropDownFieldOneLiner from '../components/DropDownFieldForOneLiner';
import InputField from '../components/InputField';
import LocationField from '../components/LocationField';
import MissingParticipantsPopup from '../components/MissingParticipantsPopup';
import { ParticipantsField } from '../components/ParticipantsField';
import TextAreaField from '../components/TextAreaField';
import { charLimits, workingDays, workingTime } from '../constants';
import { generateSlots, reArrangeSlots } from '../helpers/slots';
import { useViewSize } from '../hooks/useViewSize';
import { useStore } from '../stores';
import { toNumber } from '../utils/number';
import { sleep } from '../utils/promise';

/**
 * Since React Hooks don't work well with arrays, use this utiliy function to
 * generate hash out of slots so amount of accidential re-renders is lower
 */
const computeSlotsHash = (items: { start: string }[]): string => items.map((item) => item.start).join(';');

interface ValidationState {
  state?: 'success' | 'error';
  message: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media only screen and (max-width: 768px) {
    #fc-dom-1 {
      font-size: 1em;
    }

    .fc .fc-button {
      font-size: 0.7em;
    }
  }
`;

const ConfirmPanel = styled.div<{ theme: Theme; $themeString: string }>`
  height: 52px;
  max-width: 960px;
  width: 100%;
  background: ${(props) => (props.$themeString === 'default' ? '#ffffff' : props.theme.colorNeutralBackground1)};
  position: sticky;
  bottom: 0;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    position: sticky;
  }
`;

const Subtitle = styled(Subtitle1)`
  display: flex;
  justify-content: center;
`;

const FieldsOneLiner = styled.div`
  display: flex;
`;

const CreateMeeting: FC = (): ReactElement => {
  const { appStore } = useStore();
  const navigate = useNavigate();
  const calendarRef = useRef<InstanceType<typeof FullCalendar>>(null);
  const { theme, themeString } = useContext(TeamsFxContext);
  const [confirmed, setConfirmed] = useState(false);
  const [allSlots, setAllSlots] = useState(true);
  const [isDisabledFieldsAfterConfirm, setIsDisabledFieldsAfterConfirm] = useState<boolean>(false);
  const [emailNotificationHourInterval, setEmailNotificationHourInterval] = useState<number>(4);
  const [votingDurationHours, setVotingDurationHours] = useState<number>(0);
  const viewSize = useViewSize();

  useEffect(() => {
    if (appStore.isInTeams === false) {
      navigate('/error');
    }
  }, [appStore.isInTeams]);

  useEffect(() => {
    addAllSlotsForWeek();
  }, [appStore.events]);

  const timeZones: any[] = moment.tz
    .names()
    .map((zone: string) => {
      const label = `(${moment.tz(zone).format('Z')}) ${zone}`;
      return { value: zone, label };
    })
    .sort((z1, z2) => (z1.label > z2.label ? 1 : -1));

  useEffect(() => {
    if (appStore.teamsUserCredential && appStore.isInitialized) {
      void (async () => {
        await appStore.getUserInfo();
        await appStore.getUserEvents();
      })();
    }
  }, [appStore.teamsUserCredential, appStore.needConsent, appStore.isInitialized]);

  // Title
  const [title, setTitle] = useState<string>('');
  const [titleValidation, setTitleValidation] = useState<ValidationState>({ state: undefined, message: '' });
  const titleRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (title.length < 1) {
      setTitleValidation({ state: 'error', message: 'Title is required and should be minimum 1 characters long' });
    } else if (title.length > charLimits.title) {
      setTitleValidation({
        state: 'error',
        message: `Title is expected to be maximum ${charLimits.title} characters long`,
      });
    } else {
      setTitleValidation({ state: 'success', message: 'Correct' });
    }
  }, [title]);

  // Participants
  const participantAutocompleteRef = useRef<AutocompletePeopleRef>(null);
  const [participants, setParticipants] = useState<AutocompleteOption[]>([]);
  const [participantsValidation, setParticipantsValidation] = useState<ValidationState>({
    state: undefined,
    message: '',
  });
  const [isPriorityMeeting, setIsPriorityMeeting] = useState<boolean>(false);
  const [isMissingParticipantsPopupOpen, setIsMissingParticipantsPopupOpen] = useState<boolean>(false);
  const participantsRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (participants.length === 0) {
      // TODO: Refactor this form and use this validation rule
      // setParticipantsValidation({ state: 'error', message: 'Invite at least one attendee' });
    } else {
      setParticipantsValidation({ state: 'success', message: 'Correct' });
    }
  }, [participants]);

  // This hooks deletes duplicate emails from optional participants
  useEffect(() => {
    const newOptionalParticipants = optionalParticipants.filter(
      (optionalParticipant) => !participants.find((participant) => participant.value === optionalParticipant.value),
    );

    if (optionalParticipants.length !== newOptionalParticipants.length) {
      setOptionalParticipants(newOptionalParticipants);
    }
  }, [participants]);

  // Optional Participants
  const optionalParticipantAutocompleteRef = useRef<AutocompletePeopleRef>(null);
  const [optionalParticipants, setOptionalParticipants] = useState<AutocompleteOption[]>([]);
  const optionalParticipantsRef = useRef<HTMLInputElement>(null);

  // This hooks deletes duplicate emails from the main participants field
  useEffect(() => {
    const newParticipants = participants.filter(
      (participant) =>
        !optionalParticipants.find((optionalParticipant) => optionalParticipant.value === participant.value),
    );

    if (participants.length !== newParticipants.length) {
      setParticipants(newParticipants);
    }
  }, [optionalParticipants]);

  // Location
  const [location, setLocation] = useState<string>('');
  const [locationValidation, setLocationValidation] = useState<ValidationState>({ state: undefined, message: '' });
  const [isTeamsMeeting, setIsTeamsMeeting] = useState<boolean>(true);
  const locationRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if ((location === '' && isTeamsMeeting === true) || (location !== '' && location.length <= charLimits.location)) {
      setLocationValidation({ state: 'success', message: 'Correct' });
    } else {
      setLocationValidation({
        state: 'error',
        message: `Meeting location should not be longer than ${charLimits.location} characters and shorter than 3 characters`,
      });
    }
  }, [location, isTeamsMeeting]);

  // Duration
  const intervals = [
    { value: '00:30', label: '30 minutes' },
    { value: '01:00', label: '1 hour' },
  ];
  const [interval, setInterval] = useState<string>(intervals[0].value);
  const durationRef = useRef<HTMLButtonElement>(null);

  // Timezone
  const startDate = {
    day: moment().day,
    time: moment().format('h:mm:ss a'),
    timezone: moment.tz.guess(),
  };
  const [timeZone, setTimeZone] = useState<{ label: string; value: string }>(
    timeZones.find((item) => item.value === startDate.timezone),
  );
  const timeZoneRef = useRef<HTMLButtonElement>(null);

  // Meeting voting time end schedule
  const votingDurationOptions = [
    { value: '0', label: 'Expiration' },
    ...Array.from({ length: 23 }, (_, i) => ({
      value: `${i + 1}`,
      label: `${i + 1} ${i + 1 > 1 ? 'hours' : 'hour'}`,
    })),
  ];
  const votingDurationRef = useRef<HTMLButtonElement>(null);

  // Meeting push notifications interval
  const pushNotificationsIntervals = Array.from({ length: 4 }, (_, i) => ({
    value: `${i + 1}`,
    label: `${i + 1} ${i + 1 > 1 ? 'hours' : 'hour'}`,
  }));
  const pushNotificationsRef = useRef<HTMLButtonElement>(null);

  // Meeting description
  const [meetingDescription, setMeetingDescription] = useState<string>('');
  const [meetingDescriptionValidation, setMeetingDescriptionValidation] = useState<ValidationState>({
    state: undefined,
    message: '',
  });
  const meetingDescriptionRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (setMeetingDescription.length > charLimits.description) {
      setMeetingDescriptionValidation({
        state: 'error',
        message: `Meeting description should not be longer than ${charLimits.description} characters long`,
      });
    } else {
      setMeetingDescriptionValidation({ state: 'success', message: 'Correct' });
    }
  }, [meetingDescription]);

  // Slots
  const [slots, setSlots] = useState<CalendarEvent[]>([]);
  const [slotsValidation, setSlotsValidation] = useState<ValidationState>({ state: undefined, message: '' });

  useEffect(() => {
    if (slots.length === 0) {
      setSlotsValidation({ state: 'error', message: 'Select at least on time slot' });
    } else {
      setSlotsValidation({ state: 'success', message: 'Correct' });
    }
  }, [slots]);

  useEffect(() => {
    const newSlots = slots.map((item) => ({
      ...item,
      end: moment(item.start).clone().add(interval, 'minutes').toISOString(),
    }));
    setSlots(newSlots);
  }, [interval]);

  const handleTitle = (value: string) => {
    if (value.length <= charLimits.title) {
      setTitle(value);
    }
  };

  const handleMeetingDescription = (value: string) => {
    if (value.length <= charLimits.description) {
      setMeetingDescription(value);
    }
  };

  const events = appStore.events.map((item) => ({
    title: item.subject,
    start: item.start.dateTime,
    end: item.end.dateTime,
    fromOutlook: true,
  }));

  const calendarEvents = useMemo(() => [...events, ...slots], [computeSlotsHash([...events, ...slots])]);

  const errors = [
    titleValidation,
    participantsValidation,
    locationValidation,
    slotsValidation,
    meetingDescriptionValidation,
  ].filter((item) => item.state === 'error');

  const addSlot = useCallback(
    (selectedSlot: CalendarEvent) => {
      if (slots.map((item) => item.start).includes(selectedSlot.start)) return;
      setSlots([...slots, selectedSlot]);
    },
    [slots],
  );

  const addAllSlotsForWeek = useCallback(() => {
    const timeSlots = generateSlots({ interval, events, slots, workingDays, workingTime });
    setSlots([...slots, ...timeSlots]);
  }, [interval, workingDays, workingTime, computeSlotsHash([...events, ...slots])]);

  const handleChangeInterval = (value: string) => {
    setInterval(value);
    reArrangeSlotsForNewInterval(value);
  };

  const reArrangeSlotsForNewInterval = (newInterval: string) => {
    const timeSlots = reArrangeSlots({ interval: newInterval, events, slots, workingTime });
    setSlots([...timeSlots]);
  };

  const removeSlot = useCallback(
    (date: string) => {
      const newSlots = slots.filter((item) => !item.fromOutlook && item.start !== date);
      setSlots([...newSlots]);
    },
    [slots],
  );

  const removeAllSlots = useCallback(() => {
    setSlots([]);
  }, []);

  const createMeeting = async () => {
    setIsDisabledFieldsAfterConfirm(true);

    /**
     * Sometimes users forget to press Space key to apply email query into
     * selected tag. This prevents them from completing the form and creates
     * confusion.
     *
     * This dirty way is made to overcome this problem. First, we manually
     * trigger query parsing (ignoring trailing delimiter is important). Then we
     * have to jump into next execution cycle and hijack participants value from
     * the reference (rather than from the `participants` variable).
     *
     * TODO: In future iterations of this form this functionality should have its own place.
     * TODO: Replace MissingParticipantsPopup component with error message
     */
    participantAutocompleteRef.current?.parseQuery({ ignoreTrailingDelimeter: true });
    optionalParticipantAutocompleteRef.current?.parseQuery({ ignoreTrailingDelimeter: true });
    // The following line is crusial otherwise the participants array will be empty
    await sleep(0);

    const payload = {
      title,
      participants: (participantAutocompleteRef.current?.selectedOptions ?? []).map((participant) => participant.value),
      isPriorityMeeting,
      optionalParticipants: (optionalParticipantAutocompleteRef.current?.selectedOptions ?? []).map(
        (optionalParticipant) => optionalParticipant.value,
      ),
      location,
      interval,
      meetingDescription: meetingDescription === '' ? '' : meetingDescription,
      emailNotificationHourInterval,
      votingDurationHours,
      isTeamsMeeting,
      timeZone: timeZone.value,
      slots,
    };

    if (payload.participants.length === 0) {
      setIsMissingParticipantsPopupOpen(true);
      return;
    }

    const result = await appStore.createFreeDateMeeting(payload);
    if (result?.id) {
      setConfirmed(true);
    }
  };

  const resetPage = () => {
    setConfirmed(false);
    setIsDisabledFieldsAfterConfirm(false);
    setLocation('');
    setParticipants([]);
    setSlots([]);
    setTitle('');
    setMeetingDescription('');
    setIsTeamsMeeting(true);
  };

  const handleAllSlots = useCallback(() => {
    if (appStore.isLoading) return;
    allSlots ? removeAllSlots() : addAllSlotsForWeek();

    setAllSlots(!allSlots);
  }, [appStore.isLoading, allSlots, removeAllSlots, addAllSlotsForWeek]);

  if (confirmed) {
    return (
      <Paper padding={3}>
        <Container>
          <Subtitle>Invitations are sent to attendees!</Subtitle>
          <ButtonInnerWrapper>
            <Button appearance="primary" onClick={resetPage}>
              New meeting
            </Button>
          </ButtonInnerWrapper>
        </Container>
      </Paper>
    );
  }

  return (
    <Paper padding={3}>
      <Container>
        <InputField
          disabled={isDisabledFieldsAfterConfirm}
          focusOnElementRef={titleRef}
          value={title}
          setValue={handleTitle}
          placeholder="Add a title"
          icon={<IconChartPerson onClick={() => titleRef.current && titleRef.current.focus()} />}
          header
          cursorPointer
        />
        <ParticipantsField
          disabled={isDisabledFieldsAfterConfirm}
          ref={participantAutocompleteRef}
          selectedOptions={participants}
          excludedOptions={optionalParticipants}
          onSelectedOptionsChange={setParticipants}
          placeholder="Invite attendees (type e-mail addresses)"
          focusOnElementRef={participantsRef}
          icon={<IconPeopleAdd onClick={() => participantsRef.current?.focus()} />}
          labelPosition="after"
          switchValue="Priority"
          switchCheck={isPriorityMeeting}
          onClick={() => setIsPriorityMeeting(!isPriorityMeeting)}
          cursorPointer
        />
        <AutocompletePeopleField
          disabled={isDisabledFieldsAfterConfirm}
          ref={optionalParticipantAutocompleteRef}
          selectedOptions={optionalParticipants}
          excludedOptions={participants}
          onSelectedOptionsChange={setOptionalParticipants}
          placeholder="Invite optional attendees"
          focusOnElementRef={optionalParticipantsRef}
          icon={<IconOptionalPeopleAdd onClick={() => optionalParticipantsRef.current?.focus()} />}
          cursorPointer
        />
        <LocationField
          disabled={isDisabledFieldsAfterConfirm}
          value={location}
          setValue={setLocation}
          placeholder={isTeamsMeeting ? 'Insert additional details' : 'Insert a link or an address'}
          icon={<IconLocation onClick={() => locationRef.current && locationRef.current.focus()} />}
          labelPosition="after"
          switchValue="Teams meeting"
          switchCheck={isTeamsMeeting}
          onClick={() => setIsTeamsMeeting(!isTeamsMeeting)}
          cursorPointer
          focusOnElementRef={locationRef}
        />
        {viewSize.isMobile ? (
          <>
            <DropDownField
              data-testid="create-meeting-duration-dropdown"
              disabled={isDisabledFieldsAfterConfirm}
              elementToClickRef={durationRef}
              options={intervals}
              setValue={handleChangeInterval}
              defaultValue={intervals[0].label}
              defaultSelectedOptions={intervals[0].value}
              icon={<IconClock onClick={() => durationRef.current && durationRef.current.click()} />}
              cursorPointer
            />
            <DropDownField
              data-testid="create-meeting-timezone-dropdown"
              disabled={isDisabledFieldsAfterConfirm}
              elementToClickRef={timeZoneRef}
              options={timeZones}
              setValue={(data) => setTimeZone(timeZones.find((item) => item.value === data))}
              defaultValue={timeZones.find((item) => item.value === startDate.timezone)?.label}
              defaultSelectedOptions={timeZones.find((item) => item.value === startDate.timezone)?.value}
              icon={<IconGlobe onClick={() => timeZoneRef.current && timeZoneRef.current.click()} />}
              cursorPointer
            />
            <DropDownField
              data-testid="create-meeting-duration-dropdown"
              disabled={isDisabledFieldsAfterConfirm}
              elementToClickRef={votingDurationRef}
              options={votingDurationOptions}
              setValue={(data) => {
                const numericData = toNumber(votingDurationOptions.find((item) => item.value === data)?.value);
                if (!isNil(numericData)) {
                  setVotingDurationHours(numericData);
                }
              }}
              defaultValue={votingDurationOptions[0].label}
              defaultSelectedOptions={votingDurationOptions[0].value}
              icon={<IconClockAlarm onClick={() => votingDurationRef.current && votingDurationRef.current.click()} />}
              textAddition=" voting time"
              cursorPointer
            />
            <DropDownField
              data-testid="create-meeting-duration-dropdown"
              disabled={isDisabledFieldsAfterConfirm}
              elementToClickRef={pushNotificationsRef}
              options={pushNotificationsIntervals}
              setValue={(data) => {
                const numericData = toNumber(pushNotificationsIntervals.find((item) => item.value === data)?.value);
                if (!isNil(numericData)) {
                  setEmailNotificationHourInterval(numericData);
                }
              }}
              defaultValue={`${pushNotificationsIntervals[3]?.label} notify after`}
              defaultSelectedOptions={pushNotificationsIntervals[3]?.value}
              icon={
                <IconMailClock onClick={() => pushNotificationsRef.current && pushNotificationsRef.current.click()} />
              }
              textAddition=" notify after"
              cursorPointer
            />
          </>
        ) : (
          <>
            <FieldsOneLiner>
              <DropDownField
                data-testid="create-meeting-duration-dropdown"
                disabled={isDisabledFieldsAfterConfirm}
                elementToClickRef={durationRef}
                options={intervals}
                setValue={handleChangeInterval}
                defaultValue={intervals[0].label}
                defaultSelectedOptions={intervals[0].value}
                icon={<IconClock onClick={() => durationRef.current && durationRef.current.click()} />}
                cursorPointer
              />
              <DropDownFieldForOneLiner
                data-testid="create-meeting-timezone-dropdown"
                disabled={isDisabledFieldsAfterConfirm}
                options={timeZones}
                setValue={(data) => setTimeZone(timeZones.find((item) => item.value === data))}
                defaultValue={timeZones.find((item) => item.value === startDate.timezone)?.label}
                defaultSelectedOptions={timeZones.find((item) => item.value === startDate.timezone)?.value}
                icon={<IconGlobe onClick={() => timeZoneRef.current && timeZoneRef.current.click()} />}
                cursorPointer
              />
            </FieldsOneLiner>
            <FieldsOneLiner>
              <DropDownField
                disabled={isDisabledFieldsAfterConfirm}
                elementToClickRef={votingDurationRef}
                options={votingDurationOptions}
                setValue={(data) => {
                  const numericData = toNumber(votingDurationOptions.find((item) => item.value === data)?.value);
                  if (!isNil(numericData)) {
                    setVotingDurationHours(numericData);
                  }
                }}
                defaultValue={votingDurationOptions[0].label}
                defaultSelectedOptions={votingDurationOptions[0].value}
                icon={<IconClockAlarm onClick={() => votingDurationRef.current && votingDurationRef.current.click()} />}
                textAddition=" voting time"
                cursorPointer
              />
              <DropDownFieldOneLiner
                disabled={isDisabledFieldsAfterConfirm}
                options={pushNotificationsIntervals}
                setValue={(data) => {
                  const numericData = toNumber(pushNotificationsIntervals.find((item) => item.value === data)?.value);
                  if (!isNil(numericData)) {
                    setEmailNotificationHourInterval(numericData);
                  }
                }}
                defaultValue={`Nudge`}
                defaultSelectedOptions={pushNotificationsIntervals[3]?.value}
                icon={<IconMailClock />}
                textAddition=" notify after"
                overloadText="Nudge"
                cursorPointer
                textOverload
              />
            </FieldsOneLiner>
          </>
        )}
        <TextAreaField
          disabled={isDisabledFieldsAfterConfirm}
          value={meetingDescription}
          setValue={handleMeetingDescription}
          placeholder="Add meeting description (Optional)"
          icon={
            <IconDescription onClick={() => meetingDescriptionRef.current && meetingDescriptionRef.current.focus()} />
          }
          focusOnElementRef={meetingDescriptionRef}
          cursorPointer
        />
        <Calendar
          timeZone={timeZone.value}
          interval={interval}
          select={addSlot}
          calendarRef={calendarRef}
          events={calendarEvents}
          unselect={removeSlot}
          allSlots={allSlots}
          handleAllSlots={handleAllSlots}
          isLoading={appStore.isLoading}
          workingTime={workingTime}
          workingDays={workingDays}
        />
        <ConfirmPanel theme={theme} $themeString={themeString}>
          <ButtonInnerWrapper>
            <Button
              appearance="primary"
              disabled={appStore.isLoading || errors.length > 0}
              onClick={createMeeting}
              icon={<IconSend />}
            >
              Send invitations
            </Button>
          </ButtonInnerWrapper>
        </ConfirmPanel>
        <MissingParticipantsPopup
          isOpen={isMissingParticipantsPopupOpen}
          onClose={() => {
            setIsMissingParticipantsPopupOpen(false);
            setIsDisabledFieldsAfterConfirm(false);
          }}
        />
      </Container>
    </Paper>
  );
};

export default observer(CreateMeeting);
