import {
  AvatarGroupItem,
  Button,
  CounterBadge,
  PresenceBadge,
  Subtitle2,
  Subtitle2Stronger,
  Text,
  Tooltip,
} from '@fluentui/react-components';
import {
  ChevronDown24Regular as IconChevron,
  DismissCircle24Regular as IconDeleteVote,
  Link32Filled as IconLink,
} from '@fluentui/react-icons';
import { castArray } from 'lodash';
import moment from 'moment-timezone';
import { FC, ReactElement, useCallback, useContext, useState } from 'react';

import { FreeDateMeeting, Status, UpdateFreeDateMeetingProps } from '../../api/meetings';
import { MyMeetingsSort } from '../../constants';
import {
  AnimatedButtonVariant,
  AnimatedChevron,
  AnimatedMeetingAdditionalInfoVariant,
  BaseAnimatedWrapper,
} from '../../helpers/motions';
import { captureException } from '../../helpers/sentry';
import { currentThemeString, themeName } from '../../helpers/theming';
import { getSmallestVote, mapChoices } from '../../helpers/voting';
import { useViewSize } from '../../hooks/useViewSize';
import { useStore } from '../../stores';
import { TeamsFxContext } from '../Context';
import Location from '../Location';
import MeetingDescription from '../MeetingDescription';
import MeetingInfo from '../MeetingInfo';
import { MoreGroup } from '../MoreGroup/MoreGroup';
import Slot from '../Slot';
import SlotWrapper from '../SlotWrapper';
import TimeSlotsWrapper from '../TimeSlotsWrapper';
import CountdownTimer from './components/CountdownTimer';
import DeleteUserVotePopup from './components/DeleteUserVotePopup';
import EndVotingPopup from './components/EndVotingPopup';
import MeetingEditForm from './components/MeetingEditForm';
import { OptionalParticipantsButton } from './components/OptionalParticipantsButton';
import {
  AvatarGroupContainer,
  Container,
  MeetingTitleAndAvatarWrapper,
  Members,
  MembersBox,
  MembersBoxTitle,
  SlotListWrapper,
  StatusDot,
  StatusWrapper,
  StyledAvatarGroup,
  TitleWrapper,
} from './styles';
import { sortParticipantsInActionOrder } from './utils';

interface MeetingCardProps {
  data: FreeDateMeeting;
  currentSort: MyMeetingsSort;
}

export const MeetingCard: FC<MeetingCardProps> = (props): ReactElement => {
  const { theme, themeString } = useContext(TeamsFxContext);
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZone = `(${moment.tz(zone).format('Z')}) ${zone}`;
  const smallestVote = getSmallestVote(props.data.votes || []);
  const { appStore } = useStore();
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [endVotingPopupOptions, setEndVotingPopupOptions] = useState<UpdateFreeDateMeetingProps | undefined>(undefined);
  const [deleteUserVotePopup, setDeleteUserVotePopup] = useState<boolean>(false);
  const [voteData, setVoteData] = useState<{
    meetingId: string;
    participant: string;
    slotIds: string[];
  } | null>(null);
  const isMoreGroupButtonsVisibleBase = true;
  const isCloseVisible =
    props.data.status === Status.IN_APPROVAL && !isEditMode && props.currentSort === MyMeetingsSort.ACTIVE_MEETINGS;
  const isEditVisible =
    [Status.IN_APPROVAL, Status.APPROVED, Status.FORCE_APPROVED].includes(props.data.status) &&
    !isEditMode &&
    [MyMeetingsSort.ACTIVE_MEETINGS, MyMeetingsSort.APPROVED].includes(props.currentSort);
  const isNudgeVisible =
    props.data.status === Status.IN_APPROVAL && !isEditMode && props.currentSort === MyMeetingsSort.ACTIVE_MEETINGS;
  const isCancelVisible =
    ![Status.EXPIRED, Status.CANCELLED].includes(props.data.status) &&
    !isEditMode &&
    [MyMeetingsSort.ACTIVE_MEETINGS, MyMeetingsSort.APPROVED].includes(props.currentSort);
  const viewSize = useViewSize();
  const [isFullViewCollapsed, setIsFullViewCollapsed] = useState<boolean>(false);

  const choicesList = mapChoices({
    existingSlots: props.data.slots,
    slotsIds: smallestVote?.slotIds || [],
    hideDisabled: false,
  });

  // TODO: find a way to do same thing w\o messy code
  const copyToClipboard = (link: string) => {
    if (!link) return;
    try {
      const textarea = document.createElement('textarea');
      // C stands for copied
      textarea.value = link;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      appStore.setSnackBar({ message: 'Voting link was copied successfully.', type: 'success' });
    } catch (err: any) {
      captureException(err);
      appStore.setSnackBar({ message: err.message || 'Something went wrong, please try again.', type: 'error' });
    }
  };

  const deleteUserVote = async (meetingId: string, participantId: string, slotIds: string[]) => {
    const payload = {
      meetingId: meetingId,
      voter: participantId,
      slotIds: castArray(slotIds),
    };

    const res = await appStore.deleteUserVote(payload);
    if (res) {
      setDeleteUserVotePopup(!deleteUserVotePopup);
    }

    return res;
  };

  const handleDeleteUserVote = useCallback(async () => {
    if (voteData) {
      await deleteUserVote(voteData.meetingId, voteData.participant, voteData.slotIds);
      setVoteData(null);
    }
  }, [voteData]);

  const meetingStatusText = (meetingStatus: Status) => {
    switch (meetingStatus) {
      case Status.IN_APPROVAL: {
        return 'Waiting for attendees to vote for convenient timeslots';
      }
      case Status.APPROVED: {
        return 'Confirmed';
      }
      case Status.FORCE_APPROVED: {
        return 'Force confirmed';
      }
      case Status.EXPIRED: {
        return 'Expired';
      }
      case Status.CANCELLED: {
        return 'Cancelled';
      }
    }
  };

  const handleSave = async (payload: UpdateFreeDateMeetingProps, options?: { shouldSetMeeting: boolean }) => {
    if (options?.shouldSetMeeting) {
      setEndVotingPopupOptions(payload);
    } else {
      setIsEditMode(false);
      await appStore.updateFreeDateMeeting(payload);
      setEndVotingPopupOptions(undefined);
    }
  };

  const isMeetingCancellable = (meeting: FreeDateMeeting): boolean => {
    if ([Status.APPROVED, Status.FORCE_APPROVED].includes(meeting.status)) {
      return moment
        .tz(moment(), meeting.timeZone)
        .isBefore(moment(moment.tz(meeting.winningSlot?.start, meeting.timeZone)));
    }
    // IsCloseVisible filters out all other statuses, so if it returs true then we can return it there too
    // (Button visible only for in approval and approved\force_approved not ended meetings)
    return true;
  };

  return (
    <>
      <EndVotingPopup
        open={!!endVotingPopupOptions}
        onYes={async () => endVotingPopupOptions && handleSave(endVotingPopupOptions)}
        onNo={() => setEndVotingPopupOptions(undefined)}
      />
      <DeleteUserVotePopup
        open={deleteUserVotePopup}
        onYes={async () => handleDeleteUserVote()}
        onNo={() => setDeleteUserVotePopup(!deleteUserVotePopup)}
      />
      <Container theme={theme} $themeName={themeName[currentThemeString(themeString)]}>
        <BaseAnimatedWrapper
          variants={AnimatedMeetingAdditionalInfoVariant}
          initial="closed"
          animate={isFullViewCollapsed ? 'open' : 'closed'}
        >
          <StatusWrapper>
            <StatusDot
              theme={theme}
              status={props.data.status}
              $themeName={themeName[currentThemeString(themeString)]}
            />
            <Text>{meetingStatusText(props.data.status)}</Text>
          </StatusWrapper>
          {props.data.status === Status.IN_APPROVAL && props.data.votingDurationHours && (
            <CountdownTimer
              countdownTarget={moment(props.data.createdAt).add(props.data.votingDurationHours, 'hours').toISOString()}
              timeZone={props.data.timeZone}
              meetingStatus={props.data.status}
            />
          )}
        </BaseAnimatedWrapper>

        <TitleWrapper>
          <Subtitle2Stronger>{props.data.title}</Subtitle2Stronger>
          <AnimatedChevron
            animate={{ rotate: isFullViewCollapsed ? 180 : 0 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{
              scale: 0.9,
            }}
            icon={<IconChevron />}
            onClick={() => setIsFullViewCollapsed(!isFullViewCollapsed)}
            appearance="subtle"
            transition={{ duration: 0.2 }}
          />
        </TitleWrapper>
        {isEditMode ? (
          <MeetingEditForm data={props.data} onClose={() => setIsEditMode(false)} onSave={handleSave} />
        ) : (
          <>
            <Members>
              <MembersBox theme={theme} $themeName={themeName[currentThemeString(themeString)]}>
                <MeetingTitleAndAvatarWrapper>
                  <MembersBoxTitle>
                    <Subtitle2>
                      {`Confirmed by ${
                        props.data.participants.find((item) => item === props.data.creator)
                          ? new Set(props.data.votes.filter((item) => item)).size
                          : new Set(props.data.votes.filter((vote) => vote.participant !== props.data.creator)).size
                      } of ${
                        props.data.participants.length +
                        new Set(
                          props.data.votes.filter((vote) => props.data.optionalParticipants.includes(vote.participant)),
                        ).size
                      }`}
                      {props.data.status === Status.FORCE_APPROVED && `, voting was ended early.`}
                    </Subtitle2>
                    <MoreGroup
                      isMoreGroupVisible={isMoreGroupButtonsVisibleBase}
                      isCloseVisible={isCloseVisible}
                      isEditVisible={isEditVisible}
                      isNudgeVisible={isNudgeVisible}
                      isCancelVisible={isCancelVisible && isMeetingCancellable(props.data)}
                      isMoreOpen={isMoreOpen}
                      setIsMoreOpen={setIsMoreOpen}
                      meeting={props.data}
                      onEdit={() => setIsEditMode(true)}
                      isMobile={viewSize.isMobile}
                      invitationsResendTime={props.data.invitationsResendTime}
                    />
                  </MembersBoxTitle>
                  <StyledAvatarGroup layout="stack" style={{ gap: '18px' }}>
                    {sortParticipantsInActionOrder(
                      props.data.participants,
                      props.data.votingLinks,
                      props.data.participants.find((item) => item === props.data.creator)
                        ? props.data.votes
                        : props.data.votes.filter((vote) => vote.participant !== props.data.creator),
                    ).map((item, index) => {
                      const link: string | undefined = props.data?.votingLinks?.find(
                        (votingLink) => votingLink.participant === item,
                      )?.link;
                      const isUserVoted = !!props.data.votes.find((vote) => vote.participant === item);
                      return (
                        <AvatarGroupContainer key={index}>
                          <Tooltip content={item} relationship="label">
                            <AvatarGroupItem
                              badge={{
                                style: { right: '-5px', bottom: '-5px' },
                                icon: props.data.votes.map((vote) => vote.participant).includes(item) ? (
                                  <CounterBadge
                                    size="small"
                                    overflowCount={999}
                                    count={
                                      props.data.votes.filter((obj) => obj.participant === item)[0]?.slotIds.length
                                    }
                                  />
                                ) : (
                                  <PresenceBadge status="unknown" />
                                ),
                              }}
                              color={
                                props.data.votes.map((vote) => vote.participant).includes(item)
                                  ? `${themeString === 'dark' ? 'dark-green' : 'colorful'}`
                                  : 'neutral'
                              }
                              active={
                                props.data.votingLinks.find((obj) => obj.participant === item)?.visited
                                  ? 'active'
                                  : 'unset'
                              }
                            />
                          </Tooltip>
                          {link &&
                            props.data.status === Status.IN_APPROVAL &&
                            !props.data.votes.find((vote) => vote.participant === item) && (
                              <Tooltip content={'Copy invitation link.'} relationship={'label'}>
                                <BaseAnimatedWrapper
                                  whileHover={AnimatedButtonVariant.actions.whileHover}
                                  whileTap={AnimatedButtonVariant.actions.whileTap}
                                >
                                  <Button
                                    appearance="transparent"
                                    size="large"
                                    icon={<IconLink />}
                                    onClick={() => copyToClipboard(link)}
                                  />
                                </BaseAnimatedWrapper>
                              </Tooltip>
                            )}
                          {isUserVoted && props.data.status === Status.IN_APPROVAL && (
                            <Tooltip content={'Cancel user vote.'} relationship={'label'}>
                              <BaseAnimatedWrapper
                                whileHover={AnimatedButtonVariant.actions.whileHover}
                                whileTap={AnimatedButtonVariant.actions.whileTap}
                              >
                                <Button
                                  appearance="transparent"
                                  size="large"
                                  icon={<IconDeleteVote />}
                                  onClick={() => {
                                    setVoteData({
                                      meetingId: props.data.id,
                                      participant: item,
                                      slotIds:
                                        props.data.votes.find((vote) => vote.participant === item)?.slotIds || [],
                                    });
                                    setDeleteUserVotePopup(!deleteUserVotePopup);
                                  }}
                                />
                              </BaseAnimatedWrapper>
                            </Tooltip>
                          )}
                        </AvatarGroupContainer>
                      );
                    })}
                    {props.data.optionalParticipants.length > 0 && (
                      <BaseAnimatedWrapper
                        whileHover={AnimatedButtonVariant.actions.whileHover}
                        whileTap={AnimatedButtonVariant.actions.whileTap}
                      >
                        <OptionalParticipantsButton optionalParticipants={props.data.optionalParticipants} />
                      </BaseAnimatedWrapper>
                    )}
                  </StyledAvatarGroup>
                </MeetingTitleAndAvatarWrapper>
              </MembersBox>
            </Members>
            {props.data.meetingDescription && <MeetingDescription descriptionText={props.data.meetingDescription} />}
          </>
        )}
        <BaseAnimatedWrapper
          variants={AnimatedMeetingAdditionalInfoVariant}
          initial="closed"
          animate={isFullViewCollapsed ? 'open' : 'closed'}
        >
          <Location
            location={
              props.data.location && props.data.isTeamsMeeting
                ? `${props.data.location}; Microsoft Teams Meeting`
                : props.data.isTeamsMeeting
                  ? `Microsoft Teams Meeting`
                  : props.data.location
            }
          />
          <SlotListWrapper>
            <MeetingInfo interval={props.data.interval} timeZone={timeZone} />
            {choicesList.map((item, index) => (
              <SlotWrapper key={index}>
                <Slot
                  time={item.date}
                  asDay
                  id={item.date}
                  isDisabled={item.slots.filter((slot) => !slot.isDisabled).length === 0}
                />
                <TimeSlotsWrapper>
                  {item.slots.map((item, index) => {
                    return (
                      <Slot
                        key={index}
                        time={item.time}
                        id={item.id}
                        selected={false}
                        isDisabled={item.isDisabled}
                        isWinningSlot={props.data.winningSlot?.id === item.id}
                      />
                    );
                  })}
                </TimeSlotsWrapper>
              </SlotWrapper>
            ))}
          </SlotListWrapper>
        </BaseAnimatedWrapper>
      </Container>
    </>
  );
};
