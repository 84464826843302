import { styled } from 'styled-components';

const StyledImg = styled.img`
  max-width: 150px;
  object-fit: contain;
`;

export const LogoWithText = () => {
  return <StyledImg src="/logo-text.svg" />;
};
