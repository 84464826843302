import { Theme } from '@fluentui/react-components';
import moment from 'moment';
import { FC, ReactElement, useContext } from 'react';
import styled from 'styled-components';

import { currentThemeString, themeName, WrapperThemeContstructor } from '../helpers/theming';
import { TeamsFxContext } from './Context';

const Wrapper = styled.span<{
  theme: Theme;
  $themeName: themeName;
  $isDisabled?: boolean;
  $isDisabledToInteract?: boolean;
  selected: boolean;
  $isWinningSlot?: boolean;
  $isOccupiedByCalendar?: boolean;
  $asDay?: boolean;
  onClick?: () => void;
}>`
  background-color: ${(props) => WrapperThemeContstructor(props.theme).backgroundColor[props.$themeName]};
  color: ${(props) => WrapperThemeContstructor(props.theme).color[props.$themeName]};
  pointer-events: ${(props) => (props.$isDisabledToInteract ? 'all' : 'none')};
  border-radius: 5px;
  display: flex;
  width: 75px;
  flex-shrink: 0;
  height: 35px;
  align-items: center;
  justify-content: center;
  ${(props) => props.onClick && 'cursor: pointer'};
  ${(props) => props.$themeName === 'contrast' && `outline: 1px solid ${props.theme.colorBrandForegroundLink};`}
  &:hover {
    outline: 1px solid ${(props) => props.theme?.colorBrandBackground};
    box-shadow: ${(props) => WrapperThemeContstructor(props.theme).boxShadow[props.$themeName]};
  }
  ${(props) =>
    props.$isDisabled &&
    `
    background-color: ${WrapperThemeContstructor(props.theme).isDisabled.backgroundColor};
    color: ${WrapperThemeContstructor(props.theme).isDisabled.color};
    outline: ${WrapperThemeContstructor(props.theme).isDisabled.outline};
    cursor: ${WrapperThemeContstructor(props.theme).isDisabled.cursor};
    &:hover {
      outline: ${WrapperThemeContstructor(props.theme).isDisabled.hover.outline};
      box-shadow: ${WrapperThemeContstructor(props.theme).isDisabled.hover.boxShadow};
    }
  `}
  ${(props) =>
    props.selected &&
    `
    outline: ${WrapperThemeContstructor(props.theme).selected.outline};
    background-color: ${WrapperThemeContstructor(props.theme).selected.backgroundColor};
  `}
  ${(props) =>
    props.$isWinningSlot &&
    `
    &:hover {
      outline: 1px solid ${WrapperThemeContstructor(props.theme).isWinningSlot.hover[props.$themeName]};
    }
    background-color: ${WrapperThemeContstructor(props.theme).isWinningSlot.backgroundColor[props.$themeName]};
    color: ${WrapperThemeContstructor(props.theme).isWinningSlot.color[props.$themeName]};
  `}
  ${(props) =>
    props.$isOccupiedByCalendar &&
    `
    outline: ${WrapperThemeContstructor(props.theme).isOccupiedByCalendar.selected.outline};
  `}
  ${(props) =>
    props.$isOccupiedByCalendar &&
    props.selected &&
    `
    color: ${WrapperThemeContstructor(props.theme).color[props.$themeName]};
    background-color: ${WrapperThemeContstructor(props.theme).selected.backgroundColor};
    outline: ${WrapperThemeContstructor(props.theme).isOccupiedByCalendar.selected.outline};
  `}
  ${(props) =>
    props.$asDay &&
    `
    background-color: ${WrapperThemeContstructor(props.theme).asDay.backgroundColor[props.$themeName]};
    color: ${WrapperThemeContstructor(props.theme).asDay.color[props.$themeName]};
    font-weight: ${WrapperThemeContstructor(props.theme).asDay.fontWeight};
    outline: ${WrapperThemeContstructor(props.theme).asDay.outline};
  `}
`;

const DayWithDateWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 15px;
`;

const Date = styled.span``;

const Day = styled.span`
  font-size: 11px;
  font-weight: 500;
`;

interface SlotProps {
  time: string;
  isDisabled?: boolean;
  isDisabledToInteract?: boolean;
  id?: string;
  asDay?: boolean;
  toggleSlot?: (id: string) => void;
  selected?: boolean;
  isWinningSlot?: boolean;
  isOccupiedByCalendar?: 'microsoft' | 'google';
  'data-testid'?: string;
}

const Slot: FC<SlotProps> = (props): ReactElement => {
  const { theme, themeString } = useContext(TeamsFxContext);
  const handleClick = () => {
    if (props.isDisabled) {
      return false;
    }
    props.toggleSlot && props.id && props.toggleSlot(props.id);
  };

  const label =
    props.isOccupiedByCalendar === 'microsoft'
      ? 'Outlook'
      : props.isOccupiedByCalendar === 'google'
        ? 'Google'
        : 'Occupied';

  return (
    <Wrapper
      data-testid={props['data-testid']}
      theme={theme}
      $themeName={themeName[currentThemeString(themeString)]}
      onClick={props.toggleSlot && handleClick}
      $isDisabled={props.isDisabled}
      $isDisabledToInteract={props.isDisabledToInteract}
      selected={!!props.selected}
      $asDay={props.asDay}
      $isWinningSlot={props.isWinningSlot}
      $isOccupiedByCalendar={!!props.isOccupiedByCalendar}
    >
      {props.asDay ? (
        <DayWithDateWrapper>
          <Date data-testid="voting-slot-group-date">{moment(props.time).format('MMM D')}</Date>
          <Day>{moment(props.time).format('dddd')}</Day>
        </DayWithDateWrapper>
      ) : props.isOccupiedByCalendar ? (
        <DayWithDateWrapper>
          <Date>{props.time}</Date>
          <Day>{label}</Day>
        </DayWithDateWrapper>
      ) : (
        props.time
      )}
    </Wrapper>
  );
};

export default Slot;
