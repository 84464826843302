import { BrowserAuthError, BrowserAuthErrorCodes } from '@azure/msal-browser';
import { teamsDarkTheme, teamsHighContrastTheme, teamsLightTheme, Theme } from '@fluentui/react-components';
import { app } from '@microsoft/teams-js';
import { pages } from '@microsoft/teams-js';
import { TeamsUserCredential } from '@microsoft/teamsfx';
import { isError, isNil } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { getMicrosoftAccessToken, getMicrosoftAuthUrl } from '@/api/auth';
import config from '@/config/teams.config';
import { openAuthWindow, waitAuthWindowCode } from '@/features/auth/utils/auth-window';
import { MicrosoftProviderFeatureScopes, ProviderFeature } from '@/shared';
import { type AppStore } from '@/store/AppStore';

type MicrosoftThemeString = 'default' | 'dark' | 'contrast';

export class MicrosoftStore {
  rootStore: AppStore;

  theme: Theme = teamsLightTheme; // teamsDarkTheme;

  themeString: MicrosoftThemeString = 'default'; // 'dark';

  /** @deprecated */
  teamsUserCredential: TeamsUserCredential | undefined;

  constructor(rootStore: AppStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  /**
   * Reference: https://github.com/microsoft/hack-together-teams/discussions/74
   */
  isMicrosoftTeams = false;

  async initialize() {
    let context: app.Context | undefined;

    try {
      await app.initialize();

      context = await app.getContext();
    } catch {
      /* empty */
    }

    this.isMicrosoftTeams = !isNil(context);

    // TODO: We should leave it as undefined if we're not in Teams. But we're leaving it for now for compatibility.
    this.teamsUserCredential = new TeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint,
      clientId: config.clientId,
    });

    if (!context) return;

    const themeChangeHandler = (theme: string | undefined) => {
      switch (theme) {
        case 'dark':
          this.theme = teamsDarkTheme;
          this.themeString = 'dark';
          break;
        case 'contrast':
          this.theme = teamsHighContrastTheme;
          this.themeString = 'contrast';
          break;
        case 'default':
        default:
          this.theme = teamsLightTheme;
          this.themeString = 'default';
          break;
      }
    };

    themeChangeHandler(context.app.theme);

    app.registerOnThemeChangeHandler(themeChangeHandler);
  }

  private getOauthOptions(options: { clientId?: string; features?: ProviderFeature[] } = {}): {
    authority: string;
    scopes: string[];
  } {
    const clientId = options.clientId ?? import.meta.env['REACT_APP_CLIENT_ID'];
    const tenantId = import.meta.env['REACT_APP_TENANT_ID'];
    const frontendDomain = import.meta.env['REACT_APP_TAB_DOMAIN'];

    if (!clientId || !tenantId) {
      throw new Error('Missing client ID or tenant ID');
    }

    const authority = `https://login.microsoftonline.com/${tenantId}/v2.0`;

    const featureScopes = (options.features ?? [ProviderFeature.default]).flatMap(
      (feature) => MicrosoftProviderFeatureScopes[feature],
    );

    // TODO: Move scopes to a constant in a shared package
    const scopes = [
      `api://${frontendDomain}/${clientId}/access_as_user offline_access`,
      `profile`,
      `email`,
      ...featureScopes.map((scope) => scope.value),
    ];

    console.log({ authority, scopes });

    return { authority, scopes };
  }

  async acquireAccessTokenPopupTeams(
    options: {
      /**
       * If `true`, will open up a pop-up with a sign in form.
       *
       * Openning a pop-up should follow some user action (for. ex a click),
       * therefore this can't be used in a silent flow.
       */
      forcePermissionRequest?: boolean;
    } = {},
  ): Promise<string | undefined> {
    const { forcePermissionRequest } = options;

    try {
      console.log('acquireAccessTokenPopupTeams', [this.isMicrosoftTeams, this.teamsUserCredential]);

      if (!this.teamsUserCredential) return;

      const { scopes } = this.getOauthOptions({ clientId: config.clientId });

      if (forcePermissionRequest) {
        await this.teamsUserCredential.login(scopes);
      }

      const accessToken = await this.teamsUserCredential.getToken('');

      return accessToken?.token;
    } catch (error) {
      console.error(error);

      return undefined;
    }
  }

  async acquireAccessTokenPopup(options: { features?: ProviderFeature[] } = {}): Promise<string | undefined> {
    const clientId = import.meta.env['REACT_APP_CLIENT_ID'];

    if (!clientId) {
      throw new Error('Missing client ID');
    }

    try {
      const { features } = options;

      const code = await this.acquireAccessCodePopup({ features });
      if (!code) return;

      const response = await getMicrosoftAccessToken({ code });

      return response.data.tokenSaveId;
    } catch (error) {
      if (error instanceof BrowserAuthError && error.errorCode === BrowserAuthErrorCodes.userCancelled) {
        return undefined;
      }

      throw error;
    }
  }

  async acquireAccessCodePopup(options: { features?: ProviderFeature[] } = {}): Promise<string | undefined> {
    const { features } = options;

    // Safari requires the window to be opened shortly after the user clicks the
    // button. That's why we open the empty window first and then replace it
    // with the URL we get from the API.
    const windowReference = await openAuthWindow({ url: 'about:blank' });

    const authUrlResponse = await getMicrosoftAuthUrl({ features });
    const authUrl = authUrlResponse.data.url;
    windowReference.location.replace(authUrl);

    const authCode = await waitAuthWindowCode({ windowReference });

    return authCode;
  }

  async navigateToTab(pageId: string) {
    try {
      await pages.currentApp.navigateTo({ pageId });
    } catch (error) {
      const message = (isError(error) ? error.message : undefined) ?? 'Something went wrong. Please try again later.';
      this.rootStore.setSnackBar({ message, type: 'error' });
    }
  }
}
