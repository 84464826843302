import { Theme } from '@fluentui/react-components';

export enum themeName {
  default = 'default',
  dark = 'dark',
  contrast = 'contrast',
}

export const currentThemeString = (themeString: string) => {
  if (themeString === 'dark') return 'dark';
  if (themeString === 'contrast') return 'contrast';

  return 'default';
};

export const GlobalStyleThemeConstructor = (theme?: Theme) => ({
  fcTodayBgColor: {
    default: '#ffffff',
    dark: 'rgb(41, 41, 41)',
    contrast: 'rgb(0, 0, 0)',
  },
  fcBorderColor: {
    default: '#dddddd',
    dark: '#000000',
    contrast: '#dddddd',
  },
  fcNonBusinessColor: {
    default: 'hsla(0, 0%, 82%, 0.3)',
    dark: 'hsla(0, 0%, 0%, 0.2)',
    contrast: 'hsla(0, 0%, 0%, 0.8)',
  },
  fcNowIndicatorColor: {
    default: 'rgb(218, 59, 1)',
    dark: 'rgb(218, 59, 1)',
    contrast: 'rgb(26, 235, 255)',
  },
  fcTimegridSlotLabel: {
    default: 'black',
    dark: 'white',
    contrast: 'white',
  },
  fcColHeaderCellCushion: {
    default: 'black',
    dark: 'white',
    contrast: 'white',
  },
  fc: {
    fcButtonTextColor: {
      default: 'black',
      dark: 'white',
      contrast: 'white',
    },
    fcButtonBgColor: {
      default: 'white',
      dark: theme?.colorNeutralBackground1,
      contrast: theme?.colorNeutralForeground1Hover,
    },
    fcButtonHoverBgColor: {
      default: theme?.colorNeutralStroke3,
      dark: theme?.colorNeutralBackground1,
      contrast: theme?.colorNeutralForeground1Hover,
    },
    fcButtonActiveBgColor: {
      default: theme?.colorNeutralStroke3,
      dark: theme?.colorNeutralBackground1,
      contrast: theme?.colorNeutralForeground1Hover,
    },
    fcButtonBorderColor: {
      default: theme?.colorCompoundBrandBackgroundHover,
      dark: theme?.colorBrandBackgroundStatic,
      contrast: 'white',
    },
    fcButtonHoverBorderColor: {
      default: theme?.colorBrandBackgroundStatic,
      dark: theme?.colorCompoundBrandBackgroundHover,
      contrast: theme?.colorBrandForegroundLink,
    },
    fcButtonActiveBorderColor: {
      default: theme?.colorBrandBackgroundStatic,
      dark: theme?.colorCompoundBrandBackgroundHover,
      contrast: theme?.colorNeutralForegroundDisabled,
    },
    fcButtonPrimaryNotDisabledActiveBg: {
      default: theme?.colorNeutralStroke3,
      dark: theme?.colorNeutralBackground1Selected,
      contrast: theme?.colorNeutralForeground1Hover,
    },
  },
});

export const ContainerThemeConstructor = (theme: Theme) => ({
  backgroundColor: {
    default: 'white',
    dark: theme.colorNeutralBackground1,
    contrast: theme.colorNeutralForeground1Hover,
  },
  boxShadow: {
    default: '0 17px 15px hsla(0, 0%, 92%, 0.35)',
    dark: 'none',
    contrast: '0 0 15px hsla(349, 0%, 50%, 1)',
  },
});

export const FullCalendarThemeConstructor = (theme?: Theme, fromOutlook?: boolean) => ({
  eventBackgroundColor: {
    default: 'white',
    dark: theme?.colorBrandBackgroundStatic,
    contrast: theme?.colorNeutralForeground1Hover,
  },
  eventTextColor: {
    default: theme?.colorBrandBackgroundHover,
    dark: theme?.colorBrandBackgroundInvertedPressed,
    contrast: theme?.colorNeutralForeground1,
  },
  backgroundColor: {
    default: fromOutlook ? theme?.colorBrandBackground : 'white',
    dark: fromOutlook ? theme?.colorBrandBackground2Pressed : theme?.colorBrandBackgroundStatic,
    contrast: theme?.colorNeutralForeground1Hover,
  },
  borderColor: {
    default: fromOutlook ? 'white' : theme?.colorBrandBackground,
    dark: fromOutlook ? theme?.colorCompoundBrandBackground : theme?.colorBrandBackground,
    contrast: fromOutlook ? theme?.colorNeutralForeground1 : theme?.colorCompoundBrandForeground1,
  },
  textColor: {
    default: fromOutlook ? 'white' : theme?.colorBrandBackgroundHover,
    dark: fromOutlook ? theme?.colorBrandForeground2 : theme?.colorBrandBackgroundInvertedPressed,
    contrast: fromOutlook ? theme?.colorBrandForegroundLink : theme?.colorNeutralForeground1,
  },
});

export const MeetingCardContainerThemeConstructor = (theme: Theme) => ({
  backgroundColor: {
    default: '#fff',
    dark: theme.colorNeutralBackground3Hover,
    contrast: theme.colorNeutralForeground2Hover,
  },
});

export const StatusDotThemeConstructor = (theme: Theme) => ({
  backgroundApproved: {
    default: theme.colorPaletteGreenBackground2,
    dark: theme.colorPaletteGreenForeground2,
    contrast: theme.colorPaletteRedBorderActive,
  },
  backgroundForceApproved: {
    default: theme.colorPaletteYellowBackground3,
    dark: theme.colorPaletteYellowBackground3,
    contrast: theme.colorBrandForegroundLink,
  },
  backgroundNotApproved: {
    default: theme.colorBrandBackground || 'gray',
    dark: theme.colorBrandBackground || 'gray',
    contrast: theme.colorBrandBackground || 'gray',
  },
  backgroundCancelled: {
    default: theme.colorPaletteRedForeground3,
    dark: theme.colorPaletteRedBackground3,
    contrast: theme.colorBrandBackground,
  },
});

export const MembersBoxThemeConstructor = (theme: Theme) => ({
  background: {
    default: '#f7f7f7',
    dark: theme.colorNeutralBackground1Pressed,
    contrast: theme.colorNeutralForeground1Hover,
  },
});

export const WrapperThemeContstructor = (theme: Theme) => ({
  backgroundColor: {
    default: '#f6f6f6',
    dark: theme.colorBrandBackground2Pressed,
    contrast: theme.colorBrandBackground2Pressed,
  },
  color: {
    default: '#394453',
    dark: theme.colorBrandForeground2,
    contrast: theme.colorBrandForeground2,
  },
  boxShadow: {
    default: '0px 2px 15px rgba(164, 176, 189, 0.35)',
    dark: 'none',
    contrast: 'none',
  },
  isDisabled: {
    backgroundColor: '#f35464',
    color: '#fff',
    outline: 'none',
    cursor: 'not-allowed',
    hover: {
      outline: 'none',
      boxShadow: '0',
    },
  },
  selected: {
    outline: `1px solid ${theme.colorBrandBackground}`,
    backgroundColor: '#eef4ff',
  },
  isWinningSlot: {
    hover: {
      default: theme.colorPaletteDarkGreenBorderActive,
      dark: theme.colorPaletteDarkGreenBorderActive,
      contrast: theme.colorPaletteLightGreenBorder1,
    },
    backgroundColor: {
      default: theme.colorPaletteGreenBackground2,
      dark: theme.colorPaletteGreenForeground2,
      contrast: theme.colorCompoundBrandForeground1,
    },
    color: {
      default: '#394453',
      dark: theme.colorPaletteLightGreenBackground1,
      contrast: theme.colorPaletteLightGreenBackground1,
    },
  },
  isOccupiedByCalendar: {
    backgroundColor: {
      default: theme.colorBrandForegroundInverted,
      dark: theme.colorBrandForegroundInverted,
      contrast: theme.colorBrandForegroundInverted,
    },
    color: {
      default: theme.colorNeutralForegroundInverted,
      dark: theme.colorNeutralForegroundInverted,
      contrast: theme.colorNeutralForegroundInverted,
    },
    selected: {
      outline: `1px solid ${theme.colorBrandBackground}`,
    },
  },
  asDay: {
    backgroundColor: {
      default: '#ffff',
      dark: theme.colorNeutralBackgroundStatic,
      contrast: theme.colorNeutralBackgroundStatic,
    },
    color: {
      default: '#394453',
      dark: '#ffff',
      contrast: '#ffff',
    },
    fontWeight: '700',
    outline: '1px solid #f6f6f6',
  },
});

export const StyledTabListThemeConstructor = (theme: Theme) => ({
  backgroundColor: {
    default: '#f7f7f7',
    dark: theme.colorNeutralBackground1Pressed,
    contrast: theme.colorNeutralForeground1Hover,
  },
});

export const StyledMenuActionButtonThemeConstructor = (theme: Theme) => ({
  borderColor: {
    default: theme?.colorCompoundBrandBackgroundHover,
    dark: theme?.colorBrandBackgroundStatic,
    contrast: 'white',
  },
  borderColorOnHover: {
    default: theme?.colorBrandBackgroundStatic,
    dark: theme?.colorCompoundBrandBackgroundHover,
    contrast: theme?.colorBrandForegroundLink,
  },
  color: {
    default: theme?.colorNeutralForeground2Hover,
    dark: theme?.colorNeutralForeground2,
    contrast: theme?.colorNeutralForeground2,
  },
});
