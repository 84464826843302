import { makeAutoObservable } from 'mobx';

import { getGoogleAccessToken, getGoogleAuthUrl } from '@/api/auth';
import { openAuthWindow, waitAuthWindowCode } from '@/features/auth/utils/auth-window';
import { GoogleProviderFeatureScopes, ProviderFeature } from '@/shared';
import { type AppStore } from '@/store/AppStore';

export class GoogleStore {
  rootStore: AppStore;

  constructor(rootStore: AppStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initialize() {
    return Promise.resolve();
  }

  private getOauthOptions(options: { features?: ProviderFeature[] } = {}): {
    scopes: string[];
  } {
    const featureScopes = (options.features ?? [ProviderFeature.default]).flatMap(
      (feature) => GoogleProviderFeatureScopes[feature],
    );

    // TODO: Move scopes to a constant in a shared package
    const scopes = ['openid', 'profile', 'email', ...featureScopes.map((scope) => scope.value)];

    console.log({ scopes });

    return { scopes };
  }

  async acquireAccessTokenPopup(
    options: { features?: ProviderFeature[]; forceConsent?: boolean } = {},
  ): Promise<string | undefined> {
    const { features, forceConsent } = options;

    const code = await this.acquireAccessCodePopup({ features, forceConsent });
    if (!code) return;

    const response = await getGoogleAccessToken({ code });

    return response.data.tokenSaveId;
  }

  /**
   *
   * Reference: https://github.com/MomenSherif/react-oauth/blob/5c5ab23e93945bea9d372cdb05e8ff17b944fe30/packages/%40react-oauth/google/src/hooks/useGoogleLogin.ts
   */
  async acquireAccessCodePopup(
    options: { features?: ProviderFeature[]; forceConsent?: boolean } = {},
  ): Promise<string | undefined> {
    const { features, forceConsent } = options;

    // Safari requires the window to be opened shortly after the user clicks the
    // button. That's why we open the empty window first and then replace it
    // with the URL we get from the API.
    const windowReference = await openAuthWindow({ url: 'about:blank' });

    const authUrlResponse = await getGoogleAuthUrl({ features, forceConsent });
    const authUrl = authUrlResponse.data.url;
    windowReference.location.replace(authUrl);

    const authCode = await waitAuthWindowCode({ windowReference });

    return authCode;

    // return code;
  }
}
