import { UserInfo } from '@microsoft/teamsfx';
import { isError, throttle } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import ms from 'ms';

import { getCurrentSession, signInWithGoogleToken, signInWithMicrosoftToken, signOut } from '@/api/auth';
import { post } from '@/api/helpers';
import { persistConfig } from '@/config/store.config';
import { type AppStore } from '@/store/AppStore';
import { LoadingStoreFactory } from '@/store/LoadingStoreFactory';
import { keepNonNullish } from '@/utils/array';
import { environment } from '@/utils/config';
import { getErrorCode, getErrorMeta } from '@/utils/error';
import { sleep } from '@/utils/promise';

export class AuthStore {
  private _initialized = false;

  get initialized() {
    return this._initialized;
  }

  rootStore: AppStore;

  loading = new LoadingStoreFactory({ names: ['signIn', 'signInMicrosoft', 'signInGoogle', 'signOut'] });

  integrationProvider?: 'microsoft' | 'google';

  isSignedIn = false;

  signInPromise: Promise<void>;

  get sessionTag(): string {
    const { microsoftStore } = this.rootStore;

    const sessionEnvironment = environment !== 'production' ? environment : undefined;
    const sessionSandbox = microsoftStore?.isMicrosoftTeams ? 'ms365' : undefined;
    const sessionTag = [sessionEnvironment, sessionSandbox].filter(keepNonNullish).join('-');

    return sessionTag;
  }

  isThirdPartyCookiesAllowed = false;

  get isAuthPossible(): boolean {
    return this.isThirdPartyCookiesAllowed || !this.rootStore.microsoftStore.isMicrosoftTeams;
  }

  private signInPromiseResolve: () => void;

  constructor(rootStore: AppStore) {
    this.rootStore = rootStore;

    const { promise, resolve } = Promise.withResolvers<void>();
    this.signInPromise = promise;
    this.signInPromiseResolve = resolve;

    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      ...persistConfig,
      name: 'AuthStore',
      properties: ['isSignedIn'],
    });
  }

  async initialize(): Promise<void> {
    if (this._initialized) return;

    try {
      await this.detectThirdPartyCookiesAllowed();
      await this.signInSilent();
    } finally {
      this._initialized = true;
    }
  }

  async signInSilent(): Promise<void> {
    if (!this.isAuthPossible) return;

    const { microsoftStore } = this.rootStore;

    try {
      const result = await getCurrentSession();

      if (result.data.user) {
        this.rootStore.userStore.currentUser = result.data.user;
        this.isSignedIn = true;
        this.integrationProvider = result.data.integration?.providerType;
      } else if (microsoftStore.isMicrosoftTeams) {
        await this.signInWithMicrosoft({ mode: 'teams' });
      } else {
        this.isSignedIn = false;
      }
    } finally {
      this.signInPromiseResolve();
      this._initialized = true;
    }
  }

  signInWithMicrosoft(options: { mode?: 'default' | 'teams'; forcePermissionRequest?: boolean } = {}): Promise<void> {
    const { mode = 'default', forcePermissionRequest } = options;

    return this.loading.factory('signInMicrosoft', async () => {
      try {
        const { microsoftStore } = this.rootStore;

        let accessToken: string | undefined;
        let code: string | undefined;

        if (mode === 'default') {
          code = await microsoftStore.acquireAccessCodePopup();
        } else if (mode === 'teams') {
          accessToken = await microsoftStore.acquireAccessTokenPopupTeams({
            forcePermissionRequest,
          });
        }

        if (!code && !accessToken) {
          this.isSignedIn = false;
          return;
        }

        const result = await signInWithMicrosoftToken({ code, accessToken });

        if (result?.data.user) {
          this.rootStore.userStore.currentUser = result.data.user;
          this.isSignedIn = true;
          this.integrationProvider = result.data.integration?.providerType;
        } else {
          this.isSignedIn = false;
        }
      } catch (error) {
        const { code, message } = getErrorMeta(error);
        const isInsufficientPermissionsError = code === 'INTEGRATION_ACCESS_INSUFFICIENT_PERMISSIONS_ERROR';

        if (!isInsufficientPermissionsError) {
          this.rootStore.setSnackBar({ message, type: 'error' });
        }
      }
    });
  }

  async requestPermissions(): Promise<void> {
    const { isMicrosoftTeams } = this.rootStore.microsoftStore;

    if (this.integrationProvider === 'microsoft') {
      await this.signInWithMicrosoft({
        mode: isMicrosoftTeams ? 'teams' : 'default',
        forcePermissionRequest: true,
      });
    } else if (this.integrationProvider === 'google') {
      await this.signInWithGoogle();
    }
  }

  signInWithGoogle(): Promise<void> {
    return this.loading.factory('signInGoogle', async () => {
      try {
        const { googleStore } = this.rootStore;

        const code = await googleStore.acquireAccessCodePopup();
        if (!code) return;

        const result = await signInWithGoogleToken({ code });

        if (result.data.user) {
          this.rootStore.userStore.currentUser = result.data.user;
          this.isSignedIn = true;
          this.integrationProvider = result.data.integration?.providerType;
        } else {
          this.isSignedIn = false;
        }
      } catch (error) {
        const message = (isError(error) ? error.message : undefined) ?? 'Something went wrong. Please try again later.';
        this.rootStore.setSnackBar({ message, type: 'error' });
      }
    });
  }

  refreshSession = throttle(
    async (): Promise<void> => {
      const { lockStore } = this.rootStore;

      try {
        await lockStore.acquireFactory(
          ['authStore', 'refreshSession'],
          async () => {
            await post('postSessionRefresh', {});
          },
          { timeout: ms('5 seconds') },
        );
      } catch (error) {
        const errorCode = getErrorCode(error);

        if (errorCode === 'SESSION_REFRESH_ERROR' || errorCode === 'SESSION_NOT_FOUND_ERROR') {
          await this.signOut();
        } else {
          throw error;
        }
      }
    },
    1000,
    { trailing: false },
  );

  async signOut(): Promise<void> {
    try {
      await this.loading.factory('signOut', async () => {
        await signOut();
      });
    } finally {
      this.isSignedIn = false;
    }
  }

  async getTeamsCredentialsUserInfo(): Promise<UserInfo | undefined> {
    const isMicrosoftTeams = this.rootStore.microsoftStore.isMicrosoftTeams;
    if (!isMicrosoftTeams) return;

    const result = await this.rootStore.teamsUserCredential?.getUserInfo();

    return result;
  }

  async detectThirdPartyCookiesAllowed(): Promise<boolean> {
    const id = `3rd-party-cookies-${Math.random()}`;

    const frame = document.createElement('iframe');
    frame.id = id;
    frame.src = 'https://mindmup.github.io/3rdpartycookiecheck/start.html';
    frame.style.display = 'none';
    frame.style.position = 'fixed';

    const detectionPromise = Promise.withResolvers<boolean>();

    let isHandled = false;
    const callback = (event: MessageEvent) => {
      if (!isHandled && (event.data === 'MM:3PCsupported' || event.data === 'MM:3PCunsupported')) {
        isHandled = true;
        const isAllowed = event.data === 'MM:3PCsupported';

        this.isThirdPartyCookiesAllowed = isAllowed;
        detectionPromise.resolve(isAllowed);
      }
    };

    window.addEventListener('message', callback);
    try {
      document.body.appendChild(frame);
    } catch {
      /* empty */
    }

    await Promise.race([detectionPromise.promise, sleep(5000)]);

    window.removeEventListener('message', callback);
    try {
      document.body.removeChild(frame);
    } catch {
      /* empty */
    }

    return this.isThirdPartyCookiesAllowed;
  }
}
