import { isBoolean } from 'lodash';
import { forwardRef } from 'react';

import { CommonProps } from '@/utils/props';

import { StyledRootDiv } from './styles';
import type { StackAlignContent, StackAlignItems, StackAlignSelf, StackDirections, StackJustifyContent } from './types';
import { getFlexDirection, getFlexProps } from './utils';

export type StackProps = CommonProps<{
  /** Defines the `flex-direction` style property. */
  direction?: StackDirections;
  /** Defines the `align-content` style property. */
  alignContent?: StackAlignContent;
  /** Defines the `align-items` style property. */
  alignItems?: StackAlignItems;
  /** Defines the `align-self` style property. */
  alignSelf?: StackAlignSelf;
  /** Defines the `justify-content` style property. */
  justifyContent?: StackJustifyContent;
  /** Defines the space between immediate children. */
  spacing?: number;
  /** Defines the `flex-grow` style property. */
  grow?: number | boolean;
  /** Defines the `flex-shrink` style property. */
  shrink?: number | boolean;
  /** If `true`, sets the `display` style property to `inline-flex`, otherwise the value is `flex`. */
  inline?: boolean;
  /** If `true`, sets the `flex-wrap` style property to `wrap`. */
  wrap?: boolean;
  /** If `true`, sets the `width` style property to `100%`. */
  fullWidth?: boolean;
  /** If `true`, sets the `height` style property to `100%`. */
  fullHeight?: boolean;
}>;

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  (
    {
      direction = 'end',
      alignContent,
      alignItems,
      alignSelf,
      justifyContent,
      spacing: spacingProps = 0,
      grow,
      shrink,
      inline,
      wrap = false,
      fullWidth,
      fullHeight,
      children,
      ...restProps
    },
    parentRef,
  ) => {
    const formatNumberValue = (value: number | boolean | undefined) => (isBoolean(value) ? (value ? 1 : 0) : value);

    const growNumber = formatNumberValue(grow);
    const shrinkNumber = isBoolean(shrink) ? (shrink ? 1 : 0) : shrink;

    const spacing = spacingProps * 8;

    return (
      <StyledRootDiv
        {...restProps}
        ref={parentRef}
        $direction={getFlexDirection(direction)}
        $alignContent={getFlexProps(alignContent)}
        $alignItems={getFlexProps(alignItems)}
        $alignSelf={getFlexProps(alignSelf)}
        $justifyContent={getFlexProps(justifyContent)}
        $spacing={spacing}
        $grow={growNumber}
        $shrink={shrinkNumber}
        $inline={inline}
        $wrap={wrap}
        $fullWidth={fullWidth}
        $fullHeight={fullHeight}
      >
        {children}
      </StyledRootDiv>
    );
  },
);
