import { CalendarEvent } from '../components/Calendar';
import { deleteHttp, get, post } from './helpers';

export enum Status {
  IN_APPROVAL = 'IN_APPROVAL',
  APPROVED = 'APPROVED',
  FORCE_APPROVED = 'FORCE_APPROVED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export interface PostFreeDateMeetingProps {
  title: string;
  participants: string[];
  isPriorityMeeting: boolean;
  optionalParticipants: string[];
  votingDurationHours: number;
  emailNotificationHourInterval: number;
  location: string;
  interval: string;
  meetingDescription?: string;
  isTeamsMeeting: boolean;
  slots: CalendarEvent[];
}

export interface UpdateFreeDateMeetingProps {
  id: string;
  participants: string[];
  isPriorityMeeting: boolean;
  optionalParticipants: string[];
  meetingDescription?: string;
}

export interface Vote {
  id: string;
  participant: string;
  slotIds: string[];
  createdAt?: string;
}

export interface FreeDateMeeting extends PostFreeDateMeetingProps {
  id: string;
  creator: string;
  creatorDisplayName: string;
  links: string[];
  votingLinks: { participant: string; link: string; visited: boolean }[];
  invitationsResendTime?: string;
  timeZone: string;
  createdAt: string;
  votes: Vote[];
  isMeetingSent: boolean;
  status: Status;
  winningSlot?: { id: string; start: string };
}

interface getFreeDateMeetingByTokenResponse {
  meeting: FreeDateMeeting;
  tokenData: { email: string; id: string };
}

export interface PostVoteForMeetingProps {
  token: string;
  slotIds: string[];
}

export interface PostResendInvitationsProps {
  meetingId: string;
}

export interface PostEndVotingEarlyProps {
  meetingId: string;
}

export interface DeleteUserVoteProps {
  meetingId: string;
  voter: string;
  slotIds: string[];
}

export interface PostCancelMeetingProps {
  meetingId: string;
}

export const postFreeDateMeeting: (payload: PostFreeDateMeetingProps) => Promise<{ data: FreeDateMeeting }> = async (
  payload,
) => await post('postFreeDateMeeting', payload);

export const updateFreeDateMeeting: (
  payload: UpdateFreeDateMeetingProps,
) => Promise<{ data: FreeDateMeeting }> = async (payload) => await post('updateFreeDateMeeting', payload);

export const getMyMeetings: () => Promise<{ data: FreeDateMeeting[] }> = async () => await get('getMyMeetings');

export const getMyMeetingsAll: () => Promise<{ data: FreeDateMeeting[] }> = async () => await get('getMyMeetingsAll');

export const getFreeDateMeetingByToken: (
  token: string,
) => Promise<{ data: getFreeDateMeetingByTokenResponse }> = async (token) =>
  await get('getFreeDateMeetingByToken', { token }, { isExternal: true });

export const postVoteForMeeting: (payload: PostVoteForMeetingProps) => Promise<{ data: FreeDateMeeting }> = async (
  payload,
) => await post('postVoteForMeeting', payload, { isExternal: true });

export const postEndVotingEarly: (payload: PostEndVotingEarlyProps) => Promise<{ data: FreeDateMeeting }> = async (
  payload,
) => await post('postEndVotingEarly', payload);

export const postCancelMeeting: (payload: PostCancelMeetingProps) => Promise<{ data: FreeDateMeeting }> = async (
  payload,
) => await deleteHttp('postCancelMeeting', payload);

export const postResendInvitations: (
  payload: PostResendInvitationsProps,
) => Promise<{ data: FreeDateMeeting }> = async (payload) => await post('postResendInvitations', payload);

export const deleteUserVote: (payload: DeleteUserVoteProps) => Promise<{ data: FreeDateMeeting }> = async (payload) =>
  await deleteHttp('deleteUserVote', payload);
