import { Button, TabList } from '@fluentui/react-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Logo = styled.img`
  max-width: 150px;
  object-fit: contain;
`;

export const StyledTabList = styled(TabList)`
  display: flex;
  align-self: stretch;
  flex-grow: 1;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: stretch;
  text-decoration: none;
`;

export const LogOutButton = styled(Button)`
  margin: 20px 0;
`;
